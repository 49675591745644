<template>
  <div
    class="p-2"
    :class="{ 'area-busy': waiting }"
  >
    <FormulateForm
      v-model="formData"
      name="orderForm"
      @submit="submitHandler"
    >
      <div class="mb-2">
        <div class="formulate-input-custom">
          <label class="formulate-input-label">Location </label>
          <v-select
            v-model="selectedLocation"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="locationOptions"
            :clearable="false"
            @input="val => (formData.location = val)"
          />
        </div>

        <FormulateInput
          name="service"
          type="select"
          :options="serviceTypeOptions"
          label="Service"
          placeholder="Service"
          validation-behavior="live"
          validation="required"
        />
        <div v-if="formData.service == 1">
          <FormulateInput
            name="package"
            type="select"
            :options="packageTypeOptions"
            label="Package"
            placeholder="Package"
            validation-behavior="live"
            validation="required"
          />

          <div class="formulate-input-custom">
            <label class="formulate-input-label">Start Date </label>
            <flat-pickr
              v-model="startDate"
              class="form-control"
              :config="pickerConfig"
            />
          </div>
        </div>

        <div v-if="formData.service == 2">
          <div class="formulate-input-custom">
            <label class="formulate-input-label">Quantity </label>
            <FormulateInput
              name="quantity"
              type="number"
              placeholder="Quantity"
              validation-behavior="live"
              validation="required"
              @input="val => positiveInteger(val)"
            />
            <span>Requesting {{ (formData.quantity * 12) | formatNumber }} hours
              data for LKR
              {{ (formData.quantity * 600) | formatNumber }}/=</span>
          </div>
        </div>
      </div>

      <div class="mb-2">
        <h6 class="text-black">
          Contact Details
        </h6>

        <FormulateInput
          name="name"
          type="text"
          label="Full Name"
          placeholder="Full Name"
          validation-behavior="live"
          :validation="[['matches', /^[a-zA-Z\s]*$/], 'required']"
        />

        <FormulateInput
          name="email"
          type="email"
          label="Email"
          help="Logins will  be received to this email"
          placeholder="Email"
          validation-behavior="live"
          validation="required|email"
        />

        <FormulateInput
          name="mobile"
          type="text"
          label="Mobile No"
          placeholder="Mobile No"
          validation-behavior="live"
          validation="required|number"
        />

        <FormulateInput
          name="customer_notes"
          class="formulate-input-textarea"
          type="textarea"
          label="Note"
          placeholder="Add any special instructions or notes"
        />
      </div>

      <b-button
        variant="primary"
        :disabled="waiting"
        class="mr-1"
        block
        @click="$formulate.submit('orderForm')"
      >
        <span>Proceed</span>
      </b-button>
    </FormulateForm>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BSpinner,
  BButton,
  BImg,
} from 'bootstrap-vue'
import {
  ref, computed, watch, onMounted,
} from '@vue/composition-api'
import store from '@/store'
import _ from 'lodash'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import { formatNumber } from '@/utils/filter'
import useSideBar from './useSideBar'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BSpinner,
    BButton,
    BImg,
    vSelect,
    flatPickr,
  },
  props: {
    location: {
      type: Object,
      required: true,
    },
  },
  setup(props, { root: $this, emit }) {
    const STORE_MODULE_NAME = 'app-map'

    const formData = ref({
      location: props.location,
      service: 1,
      package: 1,
      start_date: moment().add(3, 'day').format('YYYY-MM-DD'),
      quantity: null,
    })

    const pickerConfig = {
      model: null,
      minDate: moment().add(0, 'day').format('YYYY-MM-DD'),
      defaultDate: moment().add(0, 'day').format('YYYY-MM-DD'),
    }

    const startDate = ref(null)

    watch(startDate, () => {
      formData.value.start_date = startDate.value
    })

    const selectedLocation = ref(props.location)

    const locationOptions = computed(() => (store.state[STORE_MODULE_NAME].locations
      ? _.cloneDeep(store.state[STORE_MODULE_NAME].locations)
      : []))

    const serviceTypeOptions = computed(() => (store.state.app.enums
      ? _.cloneDeep(store.state.app.enums.ServiceType)
      : []))

    const packageTypeOptions = computed(() => (store.state.app.enums
      ? _.cloneDeep(store.state.app.enums.PackageType)
      : []))

    const {
      waiting,

      submitForm,
    } = useSideBar()

    const submitHandler = data => {
      submitForm(data)
        .then(results => {
          $this.$router.push({
            name: 'app-checkout',
            params: { orderKey: results.key },
          })
        })
        .catch(() => {})
    }

    const positiveInteger = value => {
      formData.value.quantity = Math.abs(value).toFixed(0) * 1
    }

    return {
      formData,

      submitHandler,

      positiveInteger,

      waiting,

      pickerConfig,
      startDate,

      selectedLocation,

      locationOptions,
      serviceTypeOptions,
      packageTypeOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.formulate-input-custom {
  font-family: 'Lato';
  // global wrapper styles
  margin-bottom: 1rem;

  .formulate-input-label {
    // global label styles
    color: #000000;
    font-weight: 500;
    font-size: 1rem;
    display: block !important;
    margin-bottom: 0.2857rem;
    text-transform: capitalize;
  }
}
</style>

<style lang="scss">
// @import '../../../node_modules/@braid/vue-formulate/themes/snow/snow.scss';
@import '@/assets/scss/variables/_variables';

$chevron-down: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E";

.formulate-input {
  font-family: 'Lato';
  // global wrapper styles
  margin-bottom: 1rem;

  .formulate-input-label {
    // global label styles
    color: #000000;
    font-weight: 500;
    font-size: 1rem;
    display: block !important;
    margin-bottom: 0.2857rem;
    text-transform: capitalize;
  }

  .formulate-input-element {
    // Global field-level wrapper styles
  }

  .formulate-input-help {
    // Global help text styles
    color: #000000 !important;
    font-size: 0.8rem;
    padding: 0.2rem 0.4rem;
  }

  .formulate-input-errors {
    // Global error message list wrapper
  }

  .formulate-input-error,
  .formulate-file-upload-error {
    // Error message styles
    width: 100%;
    font-size: 0.857rem;
    color: #ea5455;
    margin-top: 0.25rem;
  }

  .formulate-input-group-item {
    // Group of items (like list of checkboxes) wrapper
  }

  // Text inputs
  // -----------------------------------------------------------------------------

  input {
    // Style all text-like input elements
    padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    display: block;
    width: 100%;
    height: 2.714rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.45;
    color: #6e6b7b;
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
  }

  input:focus {
    background-color: #fff;
    border-color: #7367f0;
    -webkit-box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
    -webkit-box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
    color: #6e6b7b;
    outline: 0;
  }

  &[data-classification='text'] {
    input {
      // Style all text-like input elements
    }
  }

  // Slider inputs
  // -----------------------------------------------------------------------------

  &[data-classification='slider'] {
    input {
      // Style range inputs
    }
  }

  // Textarea inputs
  // -----------------------------------------------------------------------------

  &[data-classification='textarea'] {
    textarea {
      // Style textareas
      width: 100%;
      resize: none;
      border: 1px solid #d8d6de;
      border-radius: 0.357rem;
      padding: 0.438rem 1rem;
    }

    textarea[disabled='disabled'] {
      resize: none;
    }
  }

  // Button inputs
  // -----------------------------------------------------------------------------

  &[data-classification='button'] {
    button {
      // Style button inputs
    }
  }

  // Select lists
  // -----------------------------------------------------------------------------

  &[data-classification='select'] {
    select {
      // Style select lists
      display: inline-block;
      width: 100%;
      height: 2.714rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.45;
      color: #6e6b7b;
      vertical-align: middle;
      appearance: none;
      border: 1px solid #d8d6de;
      border-radius: 0.357rem;
      padding: 0.438rem 2rem 0.438rem 1rem;
      background-position: calc(100% - 12px) 13px, calc(100% - 20px) 13px,
        100% 0;
      background-size: 18px 14px, 18px 14px;
      background-repeat: no-repeat;

      -webkit-transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, background 0s, border-color 0s,
        -webkit-box-shadow 0.15s ease-in-out;
      transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, background 0s, border-color 0s,
        -webkit-box-shadow 0.15s ease-in-out;
      transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        background 0s, border-color 0s;
      transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        background 0s, border-color 0s, -webkit-box-shadow 0.15s ease-in-out;
    }
  }

  // Box inputs
  // -----------------------------------------------------------------------------

  &[data-classification='box'] {
    .formulate-input-element {
      // Box input (checkbox and radio) wrapper (might want flex here)
      input {
        // Style box element itself
      }
    }
  }

  // File inputs
  // -----------------------------------------------------------------------------

  &[data-classification='file'] {
    .formulate-input-upload-area {
      input {
        // The actual upload element
      }
    }
  }

  // Image uploads
  // -----------------------------------------------------------------------------

  [data-type='image'] {
    // image uploads
  }
}

// Form-level errors
// -----------------------------------------------------------------------------

.formulate-form-errors {
  .formulate-form-error {
    // form errors (not specific to a field)
    width: 100%;
    font-size: 0.857rem;
    color: #ea5455;
    margin-top: 0.25rem;
  }
}

.view-only .formulate-input {
  input,
  select,
  textarea {
    color: #000000;
    border: none;
    background: transparent;
  }

  .formulate-input-help,
  .formulate-input-error {
    display: none;
  }
}
</style>
