<template>
  <div>
    <b-card
      no-body
      class="w-full max-w-[768px] mx-auto"
    >
      <b-card-body v-if="order">
        <h4>Your Order</h4>
        <div class="grid grid-cols-1 md:grid-cols-2">
          <div class="inline-flex item">
            <div>Order No: &nbsp;</div>
            <div>{{ order.id | formatOrderNumber }}</div>
          </div>

          <div class="inline-flex item">
            <div>Location: &nbsp;</div>
            <div>{{ order.location_label }}</div>
          </div>

          <div class="inline-flex item">
            <div>Service: &nbsp;</div>
            <div>{{ resolveServiceLabel(order.service) }}</div>
          </div>

          <!-- Real Time Specifics -->
          <div
            v-if="order.service == 1"
            class="inline-flex item"
          >
            <div>Package: &nbsp;</div>
            <div>{{ resolvePackageLabel(order.package) }}</div>
          </div>

          <div
            v-if="order.service == 1"
            class="inline-flex item"
          >
            <div>Start Date: &nbsp;</div>
            <div>{{ order.start_date }}</div>
          </div>

          <!-- RINEX Specifics -->
          <div
            v-if="order.service == 2"
            class="inline-flex item"
          >
            <div>Quantity: &nbsp;</div>
            <div>{{ order.quantity }}</div>
          </div>

          <div class="inline-flex item">
            <div>Amount: &nbsp;</div>
            <div>LKR {{ order.amount | formatNumber }} /= <br>(With 2.5% SSCL & 18% VAT)</div>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2">
          <div class="inline-flex item">
            <div>Name: &nbsp;</div>
            <div>{{ order.name }}</div>
          </div>
          <div class="inline-flex item">
            <div>Email: &nbsp;</div>
            <div>{{ order.email }}</div>
          </div>
          <div class="inline-flex item">
            <div>Mobile: &nbsp;</div>
            <div>{{ order.mobile }}</div>
          </div>
          <div v-if="order.customer_notes" class="inline-flex item">
            <div>Notes:: &nbsp;</div>
            <div>{{ order.customer_notes }}</div>
          </div>
        </div>
      </b-card-body>
    </b-card>

    <div
      v-if="order"
      class="w-full max-w-[768px] mx-auto"
    >
      <pay-here-pay :form-data="order" />
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BCardText, BButton,
} from 'bootstrap-vue'
import { useRoute, useRouter } from 'vue-router'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { formatOrderNumber, formatNumber } from '@/utils/filter'
import _ from 'lodash'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import checkoutStoreModule from './checkoutStoreModule'
import PayHerePay from './PayHerePay.vue'

export default {
  components: {
    BCard,
    BCardText,
    BCardBody,
    BButton,
    PayHerePay,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  setup(props, { root: $this }) {
    const STORE_MODULE_NAME = 'app-checkout'
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, checkoutStoreModule)
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const toast = useToast()
    const router = $this.$router

    const { orderKey } = $this.$route.params

    const order = ref(null)

    const getOrder = () => new Promise((resolve, reject) => {
      store
        .dispatch('app-checkout/fetchOrder', { orderKey })
        .then(({ data: { results } }) => {
          order.value = results
          resolve(results)
        })
        .catch(({ response }) => {
          reject(response)

          let message = 'Failed Process Order'
          if (response.hasOwnProperty('status') && response.status == 401) {
            message = response.data.message
          }

          if (response.hasOwnProperty('status') && response.status == 400) {
            message = response.data.message
          }

          toast(
            {
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )

          if (response.hasOwnProperty('status') && response.status == 401) {
            router.push({
              name: 'app-home',
            })
          }
        })
    })

    const resolveServiceLabel = value => _.get(_.find(store.state.app.enums.ServiceType, { value }), 'label', null)

    const resolvePackageLabel = value => _.get(_.find(store.state.app.enums.PackageType, { value }), 'label', null)

    getOrder()

    return {
      order,

      resolveServiceLabel,
      resolvePackageLabel,
    }
  },
}
</script>

<style lang="scss" scoped>
.item > div:first-child {
  font-weight: bold;
}

.item {
  margin-bottom: 1rem;
}
</style>
